@import "~antd/dist/antd.css";



p {
  font-size: 18px;
}

li {
  font-size: 18px;
}

h4 {
  color: white;
  font-size: 20px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-full-container {
  width: 100vw;
  height: calc(100vh - 60px);
  background-color: #f0f2f5;
  padding-top: 25px;
}

.Description-container {
  width: 50vw;
  margin: auto;
  height: 650px;
  background-color: white;
  margin-top: 25px;
  border: 2px solid rgba(91, 97, 110, 0.2);
  border-radius: 3px;
  box-shadow: 0 0 5px rgb(0 0 0 / 15%);
}

.Chart-container {
  width: 50vw;
  margin-left: auto;
  margin-right: auto;
  height: 650px;
  background-color: white;
  border: 2px solid rgba(91, 97, 110, 0.2);
  border-radius: 3px;
  box-shadow: 0 0 5px rgb(0 0 0 / 15%);
}

.Chart-style {
  width: 95%;
  height: 650px;
  margin: auto;
}

.Header-container {
  height: 60px;
  width: 100vw;
  background-color: white;
  border-bottom: 2px solid rgba(91, 97, 110, 0.2);
  border-radius: 3px;
  box-shadow: 0 0 5px rgb(0 0 0 / 15%);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-menu-inline {
  border-right: unset;
  border: none;
}

.ant-menu-item-selected {
  color: white;
  border: none;
}

.ant-menu-horizontal:not(.ant-menu-dark) {
  border: none;
}

.ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-item-selected {
  background-color: rgb(10,12,18);
  color: white;
  border: none;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 1px solid rgba(164,164,164,.35);
}

.ant-menu-item-active::after {
  border: none;
}

.ant-menu-item:hover {
  background-color: rgba(29,41,57,1);
  color: white !important;
  border: none;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  color: white;
  border: none;
}

.ant-menu-horizontal:not(.ant-menu-dark):hover {
  border: none;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none !important;
  transition: none !important;
}


.ant-menu-title-content {
  padding-left: 20px;
}

.ant-menu-item .ant-menu-item-icon, .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
  font-size: 20px;
}

.ant-select {
  background-color: inherit;
  color: white;
}

.ant-select-selector {
  background-color: inherit;
  color: white;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  color: white;
  border: none
}

.ant-select-dropdown {
  /* background-color: inherit; */
  background-color: #0a0c12;
  color: white;
}

/* .ant-select-selection-search {
  border-bottom: 1px solid rgba(164,164,164,.35)  ;
} */

/* .ant-select-selection-item {
  border-bottom: 1px solid rgba(164,164,164, .5);
} */

h1 {
  color: white
}

.ant-select-arrow {
  /* color: rgba(164,164,164,.5); */
  color: white;
  font-size: 10px;
}

.ant-radio-button-wrapper {
  background-color: rgb(22,24,29);
  color: rgb(100,116,139);
  border: none;
}

/* This handles the first item in the radio group */
.ant-radio-button-wrapper:first-child {
  border: none;
}

/* This handles the 2nd - N radio group items */
.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: rgb(22,24,29);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: white;
  background-color: rgb(22,24,29);
}

.ant-radio-button-wrapper:hover {
  color: white;
  background-color: rgb(22,24,29);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  border-color: none;
  color: white;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
background-color: none;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  box-shadow: none;
}

.ant-radio-button-wrapper {
  padding: 0px 5px;
  font-size: 12px;
}

iframe {
  border-radius: 25px
}


.setting-wheel-dashboard-chart {
  
  padding-left: 10px;
  padding-right: 15px;
  font-size: 18px;
  color: #64748b;
  cursor: pointer;
}

.setting-wheel-dashboard-chart:hover {
  color: white;
  cursor: pointer;
}

.ant-popover-inner {
  background-color: rgb(22,24,29);
}

.ant-popover-message {
  color: white;
}


.add-series-button {
  background-color: transparent;
  color: #64748b;
  border-color: #64748b;
  font-size: 12px;
}

.add-series-button:hover {
  border-color: white;
  cursor: pointer;
  background-color: transparent;
  color: white;
}

.full-screen-button {
    color: #64748b;
    /* margin-right: 15px; */
    font-size: 17px;
    cursor: pointer;
}

.full-screen-button:hover {
  cursor: pointer;
  color: white;
}


.ant-select-item-group {
  color: white;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: transparent;
  
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgb(22, 24, 29);;
}

.feature-article-title:hover {
  text-decoration: underline;
}

.blog-header-nav-option {
  width: 33%; 
  text-align: center;
}

.ant-tabs-top {
  border-bottom: 1px solid rgba(164,164,164,.35) !important;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 1px solid rgba(164,164,164,.5) !important;
}

.ant-tabs-tab {
  color: rgb(100, 116, 139);
  font-size: 16px;
}

.ant-tabs-tab:hover {
  color: white;
}

.ant-tabs-tab-active {
  color: white;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white;
}

.ant-tabs-ink-bar {
  background: white;
}

.ant-btn-dashed:focus {
  background: none;
  color:rgb(100, 116, 139);
  border-color: rgb(100, 116, 139);
}

.kg-card.kg-image-card {
  text-align: center;
}


.kg-image {
  height: auto;
  max-width: 100%;
}

.test-class {
  color: white;
  font-size: 45px;
}

.series-1 {
  color: #7cb5ec
}


@media screen and (min-width: 1600px) {
  .row-test {
    justify-content: space-between;
  }

  .date-col {
    margin-right: 8px;
  }

}

@media screen and (max-width: 1930px) {


  .settings-col {
    margin-left: auto;
    
  }
}

@media screen and (max-width: 1450px) {


  .settings-col {
    margin-left: 0;
  }

  .date-col {
    margin-left: auto;
  }
}

@media only screen and (max-width: 480px) {
    .desktop-table{
      display: none;
    }
}
@media only screen and (min-width: 480px) {
    .mobile-table{
      display: none;
    }

    .moblie-layout{
      margin-top:2.5rem!important;
      display: flex;
      flex-direction:column;
    }
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td 
{
  /* padding:3px!important;
  font-size:11px!important;
  margin:5px; */
}
.ant-table{
  background: #191717;
}

.ant-table .ant-table-cell{
  background:#191717!important;
}

.ant-table-thead .ant-table-cell {
  color:#fff!important;
}

.ant-table-tbody > tr > td{
  border-width: 0.1em;
  border-bottom: 1px solid #444 !important;
  font-weight: bold!important;
}

