body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 480px) {
  .ant-tabs-moblie{
    position: fixed;
  }
    .ant-tabs-nav-list{
      margin-top: 2rem!important;
      position: relative!important;
    }
    .ant-layout-header{
      line-height: 0!important;
    }
      .ant-tabs-nav-container {
          height: 100% !important;
      }
  
}
